<template>

    <div id="paywall_tailwind">

        <form class="bg-white flex flex-col gap-4"
            @submit.prevent="debounce_forms_submit()">

            <h2 class="sm:text-[24px] text-[20px] font-medium sm:mt-4 mt-6 mb-4">Kontaktoplysninger</h2>


            <div class="flex gap-4">

                <FormInputField
                    placeholder="Navn"
                    type="name"
                    :is_required="false"
                    v-model="buy_subscription_form.full_name"
                />

                <FormInputField
                    placeholder="Telefonnummer"
                    type="tel"
                    :is_required="true"
                    v-model="buy_subscription_form.phone"/>

            </div>


            <FormInputField
                placeholder="E-mail"
                type="email"
                :is_required="true"
                v-model="buy_subscription_form.email"/>


            <div class="flex gap-4">

                <FormInputField
                    placeholder="Jobtitel"
                    type="text"
                    :is_required="true"
                    v-model="buy_subscription_form.job_position"/>

                <FormInputField
                    placeholder="Firma / Kommune"
                    type="text"
                    :is_required="true"
                    v-model="buy_subscription_form.company_name"/>

            </div>

            <div class="flex gap-4">

                <FormInputField
                    placeholder="CVR-nr."
                    type="number"
                    :is_required="true"
                    v-model="buy_subscription_form.cvr_number"
                />

                <FormInputField
                    placeholder="EAN-nr."
                    type="number"
                    :is_required="false"
                    v-model="buy_subscription_form.ean_number"
                />

            </div>


            <textarea
                class="appearance-none block w-full bg-white text-gray-700 border border-1 border-[#C1C1C1] min-h-[150px] py-3 px-4 mb-4 leading-tight focus:outline-none focus:bg-white focus:ring-dark-blue"
                placeholder="Evt. besked"
                v-model="buy_subscription_form.message">
            </textarea>


            <div class="flex justify-between items-center">

                <h2 class="sm:text-[24px] text-[18px] font-medium sm:my-4 my-6">Ekstra antal brugere</h2>

                <div class="flex items-center justify-center sm:space-x-4 space-x-2">

                    <button
                        @click.stop.prevent="remove_user"
                        class="bg-[#DEDEDE] sm:w-[40px] w-[30px] sm:h-[40px] h-[30px] rounded-full flex items-center justify-center">

                        <font-awesome-icon
                            :icon="['fas', 'minus']"
                            class="text-[20px] text-white"
                        />

                    </button>

                    <p class="sm:text-[28px] text-[24px] font-bold">{{ buy_subscription_form.users.length }}</p>

                    <button
                        @click.stop.prevent="add_user"
                        class="sm:w-[40px] w-[30px] sm:h-[40px] h-[30px] rounded-full flex items-center justify-center"
                        :class="`bg-${ site.color }`">

                        <font-awesome-icon
                            :icon="['fas', 'plus']"
                            class="text-[20px] text-white"
                        />

                    </button>

                </div>

            </div>


            <div
                class="w-full"
                v-for="(user,user_index) of buy_subscription_form.users">

                <h2 class="sm:text-[18px] text-[16px] mb-2 sm:mb-1 font-medium">Bruger {{user_index + 1}}</h2>

                <div class="sm:flex sm:space-x-4 space-y-4 sm:space-y-0">

                    <input
                        class="appearance-none block w-full bg-white text-gray-700 border border-1 border-[#C1C1C1] py-5 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-dark-blue"
                        type="text"
                        placeholder="Navn"
                        v-model="buy_subscription_form.users[user_index].full_name"
                    >

                    <input
                        class="appearance-none block w-full bg-white text-gray-700 border border-1 border-[#C1C1C1] py-5 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-dark-blue"
                        type="text"
                        placeholder="E-mail"
                        v-model="buy_subscription_form.users[user_index].email"
                    >

                    <input
                        class="appearance-none block w-full bg-white text-gray-700 border border-1 border-[#C1C1C1] py-5 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-dark-blue"
                        type="number"
                        placeholder="Telefon nr."
                        v-model="buy_subscription_form.users[user_index].phone"
                    >

                </div>

            </div>

            <label class="inline-flex items-center cursor-pointer"
                for="is_terms_accepted">

                <input
                    class="form-checkbox h-5 w-5 text-[#1479FB] focus:outline-none focus:ring-none border border-1 border-[#C1C1C1]"
                    id="is_terms_accepted"
                    type="checkbox"
                    v-model="buy_subscription_form.is_terms_accepted">

                <p class="ml-2 text-gray-700">Jeg accepterer <a href="/politikker" class="text-[#1479FB]" target="_blank">vilkårene</a></p>

            </label>


            <button
                class="hover:bg-white border-2 hover:border-2 w-full text-white py-3 px-4 sm:text-[20px] text-[16px] leading-tight"
                :class="`bg-${ site.color } border-${ site.color } hover:text-${ site.color }`"
                type="submit">

                <p class="font-medium">Ja tak - Bestil abonnement</p>

            </button>


            <messages
                class="bg-white w-full mt-2"
                :types="['buy_subscription_form']"
            ></messages>


            <p class=" my-2 italic sm:text-[17px] text-[14px] text-[#797979]">Du vil modtage et svar pr. e-mail, når vi har behandlet dit abonnement.</p>


        </form>


    </div>

</template>

<script>
import FormInputField from "../components/FormInputField.vue";

export default {

    name: "BuySubscriptionFormView",

    components: {
        FormInputField
    },

    async created() {

        let
            profile_form = await this.get_customer_profile(),
            default_form = this.buy_subscription_form
        ;

        this.buy_subscription_form = {
            ...default_form,
            ...profile_form,
            token: localStorage.getItem('paywall.token'),
            site_id: this.site.id
        };

    },

    methods: {

        debounce_forms_submit: _.debounce(function() {

            this.forms_submit(
                'buy_subscription_form',
                'We have received your inquiry'
            );

        }, 500),

        add_user() {

            this.buy_subscription_form.users.push({
                email: '',
                phone: '',
                full_name: ''
            })

        },

        remove_user() {

            if (this.buy_subscription_form.users.length === 0) return;

            this.buy_subscription_form.users.pop();

        },

    }
}
</script>

<style scoped>

</style>
